.ion-chip-card-fake {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5fa;
  min-height: 90px;
  border-radius: 7px;
  font-size: 17px;
  color: #787887;
}
