@import 'src/assets/scss/_shared/variables';

form {
  app-form-field {
    ion-radio-group {
      //background-color: indianred;
      padding-bottom: 20px;
      display: block;
      margin-left: -8px;

      ion-row {
        //background-color: cadetblue;

        ion-col {
          ion-item {
            --padding-start: 0;
            --ion-safe-area-left: 0;
            --padding-end: 0;
            --min-height: 0;

            ion-radio {

            }

            ion-label {
              color: $form-font-color !important;
              font-size: $form-font-size !important;
              font-weight: 100 !important;
              margin: 0 0 0 10px !important;
            }
          }
        }
      }
    }
  }

  ion-col {
    padding: 0 8px;
    text-align: left;
    --highlight-background: transparent;

    &.ion-invalid {
      --highlight-background: transparent;
    }

    .item-native {
      //--border-color: transparent;
    }


    .item-highlight {
      display: none;
      visibility: hidden;
    }

    ion-label {
      font-size: 13px;
      color: #1d1d1d;
      font-weight: 600;
    }

    ion-input,
    ion-select {
      color: $form-font-color !important;
      font-size: $form-font-size !important;
      --padding-start: 12px;
      border: 1px solid $form-color-border;
      border-radius: 4px;
      margin-top: 6px;
      margin-bottom: 25px;
      background-color: rgba(255, 255, 255, .9) !important;

      &:focus {
        border: 1px solid #4f357f;
      }
    }

    ion-select {
      --padding-end: 5px;

      ion-radio-group {
        ion-item {
          color: $form-font-color !important;
          font-size: $form-font-size !important;
        }
      }
    }

    .form-field-container-error {
      ion-select {
        border: 1px solid $form-color-border-error;
      }

      ion-label {
        color: $form-color-error !important;
      }

      ion-radio-group {
        ion-row {
          ion-col {
            ion-item {
              ion-radio {
                --color: $form-color-border-error;
              }
            }
          }
        }
      }

      ion-radio-group {
        ion-item {
          ion-radio {
            --color: #a9221f !important;
          }
        }
      }

      ion-input {
        border: 1px solid $form-color-error !important;
      }

      ion-checkbox {
        --border-color: #a9221f !important;

        & + .message {
          padding-top: 21px;
          margin-left: -3px;
          display: none;
        }
      }


      .message {
        &--error {
          color: $form-color-error;
        }
      }
    }

    .message {
      height: 28px;
      display: block;
      position: absolute;
      bottom: -7px;
      left: 12px;
      margin-top: 5px !important;
      font-family: "SourceSansPro" !important;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 11px;
      align-items: center;
    }
  }


  ion-button {
    &.btn-block {
      display: block;
    }

    margin-left: 3px;
    margin-right: 3px;
    margin-top: 10px;
  }

  ion-item {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --padding-end: 0;

    ion-button {
      min-width: 100px;
    }
  }
}

ion-item {
  &.item-has-focus {
    --highlight-background: transparent;
  }
}
