/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "SourceSansPro";
  src: url("assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "League Spartan";
  src: url("assets/fonts/leaguespartan/leaguespartan-bold.eot");
  src: url("assets/fonts/leaguespartan/leaguespartan-bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/leaguespartan/leaguespartan-bold.woff2") format("woff2"),
    url("assets/fonts/leaguespartan/leaguespartan-bold.woff") format("woff"),
    url("assets/fonts/leaguespartan/leaguespartan-bold.ttf") format("truetype"),
    url("assets/fonts/leaguespartan/leaguespartan-bold.svg#league_spartanbold") format("svg");
  font-weight: bold;
  font-style: normal;
}

:root {
  --ion-color-primary: #2b2670;
  --ion-color-secondary: #8c5eff;
  --ion-color-danger: #592ed4;
  --ion-color-light: #8152f6;
  --ion-color-dark: #2f3b59;
  --ion-color-light: #ffffff;
  --ion-color-medium: rgb(18, 175, 10);
  --ion-color-dark: rgb(197, 19, 19);
}

@import "./assets/scss/index";

:root {
  @import "~@swimlane/ngx-datatable/index.css";
  @import "~@swimlane/ngx-datatable/themes/material.scss";
  @import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
  @import "~@swimlane/ngx-datatable/themes/dark.scss";
  @import "~@swimlane/ngx-datatable/assets/icons.css";
}

body {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  font-family: "SourceSansPro";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
ion-buttons {
  font-family: SourceSansPro;
}

ion-input,
ion-textarea {
  font-family: SourceSansPro !important;
}

p {
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

input:focus {
  outline: none !important;
}

.btn-transparent {
  --background: transparent !important;
  --color: #000 !important;
  --box-shadow: none;
  margin-top: 9px !important;
  font-size: 1rem !important;
}

ul {
  li {
    font-family: SourceSansPro;
  }
}

ion-toast {
  font-family: SourceSansPro !important;
}

ion-label {
  font-family: SourceSansPro !important;
}

.searchbar-input.sc-ion-searchbar-md {
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  font-family: "SourceSansPro" !important;
  height: 48px;
  box-shadow: inset 0 2px 10px -1px rgba(0, 0, 0, 0.25882352941176473) !important;
}

.searchbar-input.sc-ion-searchbar-ios {
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  font-family: "SourceSansPro" !important;
  height: 48px;
  box-shadow: inset 0 2px 10px -1px rgba(0, 0, 0, 0.25882352941176473) !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  left: 16px;
  top: 14px;
  width: 21px;
  height: 21px;
  color: #8d8e90;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 16px;
  top: 14px;
  width: 21px;
  height: 21px;
  color: #8d8e90;
}

ion-item {
  font-family: SourceSansPro !important;
}

.select-text {
  font-family: SourceSansPro !important;
}

small {
  font-family: SourceSansPro !important;
}

ion-backdrop {
  opacity: 0.5 !important;
}

.action-sheet-title {
  font-weight: 600 !important;
  color: #fff !important;
  padding: 18px 16px !important;
  height: 52px !important;
  font-size: 16px !important;
  background: #f0682a !important;
}

.action-sheet-button {
  height: 44px !important;
  font-size: 15px !important;
  color: #000 !important;
  font-family: SourceSansPro !important;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  font-family: SourceSansPro;
}

.alert-title.sc-ion-alert-md,
.alert-title.sc-ion-alert-ios {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.custom-alert {
  //background-color: #FFE7E4 !important;
  background: transparent !important;
  font-family: "SourceSansPro" !important;
  color: #ff8878 !important;
  // --padding: 5px !important;
  // --margin: 5px !important;
  // --height: 80% !important;
  // --width: 100% !important;
}

.custom-alert {
  .alert-button {
    background: var(--ion-color-primary);
    color: #fff;
  }

  .alert-head {
    background: red;
    color: #fff !important;
  }

  .alert-message {
    margin-top: 20px;
    text-align: justify;
  }
}

.custom-alert-ok {
  background: transparent !important;
  font-family: "SourceSansPro" !important;
  color: #ff8878 !important;

  -webkit-user-select: text;    
  -moz-user-select: text;    
  -ms-user-select: text;    
  user-select: text;

  .alert-wrapper {
    max-width: 50%;
    //min-height: 50% !important;
  }

  .alert-button {
    background: var(--ion-color-primary);
    color: #fff;
    margin: 2px;
  }

  .alert-button-group{
    margin-bottom: 20px;
  }

  .alert-head {
    background: green;
    color: #fff !important;
  }

  .alert-message {
    margin-top: 20px;
    max-height:fit-content;
    text-align: justify;
  }


}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px 0;

  &--form {
    max-width: 1200px;
    text-align: center;
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 10px 20px -10px #777;
    bottom: 0;
    margin: 0 0 20px 0;
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

ion-content {
  --background: #e9ecef;
}

.headline-area {
  padding-bottom: 30px;

  h3 {
    //font-size: 31px;
    //line-height: 40px;
    //font-weight: 600;
    color: #444;

    font-size: 64px;
    text-transform: uppercase;
    font-weight: bold;
    color: --ion-color-primary;
  }
}

hr {
  border-bottom: 1px solid #dedede;
  padding: 0 !important;
}

::shadow .item-native {
  --background: red !important;
  --border-color: red;
  transition: var(--transition);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: red;
  outline: none;
  background: red !important;
  overflow: inherit;
  box-sizing: border-box;
}

.section-separator {
  width: 100%;
  display: block;
  position: relative;
  margin: 20px 0;
  padding: 0 0 5px;
  background: #cde7f1;
  clear: both;
  border-radius: 5px;
  left: 50%;
  top: 0;
  margin-left: -25px;
  width: 60px;
  border-radius: 5px;
}

.section-shadow {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 5px;
  font-size: 64px;
  opacity: 0.08;
  z-index: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: --ion-color-primary;
  opacity: 0.03;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid --ion-color-primary;
}


.item-separator {
  width: 100%;
  display: block;
  position: relative;
  margin: 20px 0;
  padding: 0 0 5px;
  background: #8152f6;
  clear: both;
  border-radius: 5px;
  left: 0;
  top: 0;
  margin-left: 0px;
  width: 60px;
  border-radius: 5px;
}

.dynamicModalCss {
  --width: 90% !important;
  --height: 90% !important;
}

