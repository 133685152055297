ion-modal {
	&.modal-md {
		@media (min-width: 768px) {
			.modal-wrapper {
				width: 70vw;
				height: 60vh;
			}
		}
	}

	&.modal-lg {
		@media (min-width: 768px) {
			.modal-wrapper {
				width: 80vw;
				height: 70vh;
			}
		}
	}

	&.modal-confirm {
		.modal-wrapper {
			//border: 5px solid lightsalmon;

			@media (min-width: 576px) {
				//border: 5px solid indianred;
			}

			@media (min-width: 768px) {
				//border: 5px solid darkseagreen;
				width: 450px;
				height: 300px;
			}

			@media (min-width: 992px) {
				//border: 5px solid violet;
			}

			@media (min-width: 1200px) {
				//border: 5px solid cadetblue;
			}
		}
	}

  &.modal-generic {
    .modal-wrapper {
      //border: 5px solid lightsalmon;

      @media (min-width: 576px) {
        //border: 5px solid indianred;
      }

      @media (min-width: 768px) {
        //border: 5px solid darkseagreen;
        width: 450px;
        height: 300px;
      }

      @media (min-width: 992px) {
        //border: 5px solid violet;
      }

      @media (min-width: 1200px) {
        //border: 5px solid cadetblue;
      }
    }
  }
}

.modal {
	&__header {

		ion-buttons {

			&[slot="end"] {
				.close {
					--background: transparent !important;
					--color: #fff !important;
					--box-shadow: none;
					margin: 0 !important;
					width: auto !important;
					font-size: 1.4rem !important;
				}

				ion-icon {
				}
			}
		}
	}


	&__content {
		--background: white;
	}

	&__footer {
		&:before {
			background-image: none;
		}
	}
}


