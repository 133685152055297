@mixin init {
  > ion-col {
    padding-bottom: 7px;

    &:nth-child(even) {
      border-top: 1px solid #e4e4e4;
      padding-top: 48px;
    }

    &:nth-child(odd) {
      padding-bottom: 24px;
    }
  }
}

@mixin end {
  > ion-col {
    &:nth-child(even) {
      border-top: none !important;
      padding-top: 0;
      border-left: 1px solid #e4e4e4;
      padding-left: 20px;
    }

    &:nth-child(odd) {
      padding-bottom: 7px;
      padding-right: 20px;
    }
  }
}

ion-row {
  &.row-separator {
    &--xs-sm {
      @include init;

      @media (min-width: 576px) {
        @include end;
      }
    }

    &--xs-md {
      @include init;

      @media (min-width: 768px) {
        @include end;
      }
    }
  }


  //border: 5px solid lightsalmon; // xs

  @media (min-width: 576px) { // sm
    //border: 5px solid indianred;
  }

  @media (min-width: 768px) { // md
    //border: 5px solid darkseagreen;
  }

  @media (min-width: 992px) { // lg
    //border: 5px solid violet;
  }

  @media (min-width: 1200px) { // xl
    //border: 5px solid cadetblue;
  }
}





