@import 'src/assets/scss/_shared/variables';

ion-popover {
	&.ion-select-popover {
		--width: 80%;

		ion-select-popover {

			ion-item {
				color: $form-font-color !important;
				font-size: $form-font-size !important;
			}
		}
	}

	@media (min-width: 576px) {
		&.ion-select-popover {
			--width: 350px;
		}
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
}

